/* You can add global styles to this file, and also import other style files */
// @import "styles/_lib";
// @import "styles/_nav";
// @import "styles/_buttons";
// @import "styles/_cards";
@import '@mt-ng2/styles/styles.less';

@background: #002d62;
@navy: #002d62;
@primary: @navy;
@lightPrimary: lighten(@primary, 40%);
@darkPrimary: darken(@primary, 10%);
@secondary: #2f8fff;
@lightSecondary: lighten(@secondary, 40%);
@darkSecondary: darken(@secondary, 10%);
@btnPrimaryColor: @lightPrimary;
@btnPrimaryColorActive: @primary;
@btnSuccessColor: @btnPrimaryColor;
@btnSuccessColorActive: @btnPrimaryColorActive;
@btnSelectedBorderColor: darken(@secondary, 5%);
@btnSelectedActiveBorderColor: darken(@secondary, 10%);
@btnSelectedActiveBackgroundColor: @btnSelectedBorderColor;

.fab-wrap-r {
    position: fixed;
    right: 35px;
}
ng-component.show {
    display: grid !important;
    padding: 5px;
}
ng-component.show .dropdown-item {
    padding: 5px;
    margin: 2px;
}
.typeahead {
    padding: 5px;
}
.dialog-item {
    padding: 12px !important;
}
.dropdown-menu[role="listbox"] {
    max-height: 300px;
    overflow-y: auto;
}

/*
 * Page: Remote Kiosk
 * ----------------------
 */
//  .logo {
//    font-size: 35px;
//    text-align: center;
//    margin-bottom: 25px;
//    font-weight: 300;
//  }
//  .logo a,
//  {
//    color: #444;
//  }
//  .remote-kiosk-page {
//    background: #c4dadc;
//  }
.login-box {
    width: 340px;
    margin: 2% auto;
}

.remote-kiosk-box {
    width: 500px;
    margin: 7% auto;
}
.remote-kiosk-component-box {
    width: 500px;
    margin: 0% auto;
}
.remote-kiosk-order-box {
    width: 700px;
    margin: 7% auto;
}
@media (max-width: 768px) {
    .remote-kiosk-box {
        width: 90%;
        margin-top: 20px;
    }
}
.remote-kiosk-box-body {
    // background: #fff;
    padding: 20px;
    border-top: 0;
    color: #666;
}
.login-box-body .form-control-feedback,
.register-box-body .form-control-feedback {
    color: #777;
}
.login-box-msg,
.register-box-msg {
    margin: 0;
    text-align: center;
    padding: 0 20px 20px 20px;
}
.remote-kiosk-box .logo-img {
    max-width: 100%;
    height: auto;
    margin-bottom: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.table-scroll tbody {
    display: block;
    overflow: auto;
    overflow-y: scroll;
}
.table-scroll thead,
.table-scroll tbody tr,
.table-scroll tfoot {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.max-table-height {
    max-height: 700px;
}

.spinner {
    zoom: 2;
}

/*
 * Page: Manage Scheduling
 * ----------------------
 */

.draggable-order-card {
    cursor: move !important;
    padding: 8px;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
    box-shadow: 1px 1px 2px 2px #dedede;
    margin: 5px;
}

.drop-zone {
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
    background-color: rgb(250, 250, 260);
}

.drop-zone.scheduled {
    border: 1px solid lightgray;
}

.drop-zone.unscheduled {
    border: 1px solid lightgray;
}

.scheduling-component-header {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: lightgray;
}

.scheduling-component-header.scheduled {
    background-color: rgb(159, 211, 159);
}

.scheduling-component-header.unscheduled {
    background-color: #ffcc99;
}

.time-slot-component-header {
    padding: 7px 12px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: lightblue;
}

ngb-datepicker {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    box-shadow: 1px 1px 6px 1px #bbb;
}

.ngb-dp-day > div[ng-reflect-selected='true'] {
    font-weight: bold;
    color: black;
}

.ngb-tp-hour,
.ngb-tp-minute {
    min-width: 50px;
}

.invalid-warehouse-label,
.non-twic-verified-label {
    color: red;
}

/*
 * Page: Modified Office Hour Form
 * ----------------------
 */
.modified-office-hours-swal {
    min-width: 600px;
}

/*
 * Public portal styles
 * -----------------------
 */
.public-outer {
    @media (min-width: 991.98px) {
        display: table;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
.public-middle {
    @media (min-width: 991.98px) {
        display: table-cell;
        vertical-align: middle;
    }
}

.public-container {
    @media (min-width: 991.98px) {
        margin-left: auto;
        margin-right: auto;
    }
    max-width: 900px;
    margin: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4%;
    box-shadow: 7px 9px @primary;
    .f-20 {
        padding: 6px;
        font-size: 20px;
    }
    .f-30 {
        font-size: 30px;
    }
    @media (max-width: 991.98px) {
        margin-top: -25px;
        min-height: 100vh;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 0;
    }
    input[type='text']:focus {
        outline: none;
        box-shadow: 0 1px 0 0;
        box-shadow: 0 -1px 0 0;
        box-shadow: -1px 0 0 0;
        box-shadow: 1px 0 0 0;
        box-shadow: 0 0 0 1px;
    }
    input {
        border-radius: 5px;
        margin: 0 auto;
    }
    .phone-form .errortext {
        width: 100%;
        text-align: center;
    }
    .form-group {
        margin-bottom: 45px;
        @media (max-width: 991.98px) {
            margin-bottom: 25px;
        }
    }

    .input-large input {
        font-size: 26px;
        height: 50px;
        width: 250px;
        text-align: center;
    }
    .input-medium {
        .form-input {
            width: 320px;
            margin: 5px auto;
        }
        input {
            font-size: 18px;
            height: 40px;
            width: 320px;
            text-align: left;
        }
    }
    .public-responsive button {
        @media (max-width: 991.98px) {
            font-size: 26px;
            height: 50px;
            width: 250px;
        }
    }
    button:not(.btn-nohover) {
        padding: 9px 18px;
    }
    .purple-icon {
        color: @secondary;
    }
    .page-center-message {
        margin: 30px 0;
    }
}
tr.archived {
    td {
        background-color: rgb(255, 188, 198) !important;
    }
}

tr.archived:hover {
    td {
        background-color: rgb(216, 160, 168) !important;
    }
}

tr.reschedule-requested {
    td {
        background-color: rgb(190, 190, 245) !important;
    }
}

tr.unscheduled {
    td {
        background-color: rgb(230, 170, 188) !important;
    }
}

.create-account-link {
    text-align: center;
    margin-top: 15%;
    font-weight: bold;
    border: 1px solid rgb(210 214 222);
    border-radius: 5px;
    padding: 3px;
}

.pickups-list-component-filters mt-search-bar > div > div.input-group {
    margin-bottom: 10px;
}

.pickups-list-component-filters multiselect[ng-reflect-entity="Status"] > span > button {
    background-color: @secondary;
    border-color: @secondary;
    color: #fff;
}

.pickups-list-component-filters multiselect[ng-reflect-entity="Driver"] > span > ul {
    transform: none !important;
}

.public-responsive {
    #gpay-button-online-api-id {
        min-height: 50px;
    }
}
